<template>
  <div data-app>
    <LeftMenuPage
      :menu="[
        { title: 'add', route: 'add-booking', icon: 'mdi-plus' },
        { title: 'edit', route: 'booking', icon: '' }
      ]"
      :titleMenu="'Bookings'"
      titleContent="'Tutte le prenotazioni'"
    >
      <ToolBar>
        <template v-slot:title>
          <span>{{ pageTitle }}</span>
        </template>
        <template v-slot:actions>
          <label
            for="create-btn"
            class="relative btn-tools-with-icon-disabled whitespace-nowrap"
            :class="{
              'btn-tools-with-icon': $store.getters['auth/isAdmin']
            }"
          >
            <router-link to="/bookings/list" class="flex items-center gap-4"
              ><i class="mdi mdi-chevron-left text-lg"></i> Tutte le
              prenotazioni</router-link
            >
          </label>
          <input type="checkbox" class="hidden" id="back-btn" />
        </template>
      </ToolBar>
      <div
        class="fixed overscroll-y-auto overflow-y-auto left-0 md:left-64 right-0 top-24 bottom-0 py-5"
      >
        <on-form
          :title="bookingTitle"
          :subtitle="bookingSubtitle"
          class="w-full"
        >
          <template v-slot:content>
            <div class="grid grid-cols-7 gap-4 align-baseline items-baseline">
              <div class="col-span-5 col-start-3" v-if="formData.id">
                <v-btn
                  small
                  outlined
                  :disabled="formData.status == 'approved'"
                  :class="
                    formData.status != 'approved'
                      ? 'border-green-500 text-green-600'
                      : 'text-gray-500'
                  "
                  @click="setStatus(formData, 'approved')"
                  >Approve</v-btn
                >
                <v-btn
                  small
                  outlined
                  :disabled="formData.status == 'rejected'"
                  :class="
                    formData.status != 'reject'
                      ? 'border-red-500 text-red-500'
                      : 'text-gray-500'
                  "
                  @click="setStatus(formData, 'rejected')"
                  >Reject</v-btn
                >
              </div>
              <div class="col-span-2">
                <h1>Date</h1>
                <p class="text-gray-500 text-xs">
                  Seleziona la data di inizio e di fine della prenotazione
                </p>
              </div>
              <div class="col-span-5">
                <v-row>
                  <v-col>
                    <v-menu :close-on-content-click="false" offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          label="Dal"
                          v-model="formData.startDate"
                          prepend-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                          required
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="formData.startDate"
                        width="300"
                        flat
                        :show-adjacent-months="true"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col>
                    <v-menu :close-on-content-click="false" offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          label="Al"
                          v-model="formData.endDate"
                          prepend-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                          required
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="formData.endDate"
                        width="300"
                        flat
                        :show-adjacent-months="true"
                        :min="
                          formData.startDate ||
                            new Date().toISOString().substr(0, 10)
                        "
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </div>
              <div class="col-span-2">
                <h1>Struttura</h1>
                <p class="text-gray-500 text-xs">
                  Slezionare la tipologia di struttura tra: Appartamento, Hotel
                  o Selling structure
                </p>
              </div>
              <div class="col-span-5">
                <v-select
                  prepend-icon="domain"
                  :items="structures"
                  item-text="title"
                  item-value="id"
                  v-model="formData.structureID"
                  required
                ></v-select>
              </div>
              <div class="col-span-2">
                <h1>Nome</h1>
                <p class="text-gray-500 text-xs">
                  Nome sulla prenotazione
                </p>
              </div>
              <div class="col-span-5">
                <on-input v-model="formData.fullName"> </on-input>
              </div>
              <div class="col-span-2">
                <h1>Mail</h1>
                <p class="text-gray-500 text-xs">
                  Nome sulla prenotazione
                </p>
              </div>
              <div class="col-span-5">
                <on-input v-model="formData.email"> </on-input>
              </div>
              <div class="col-span-2">
                <h1>People</h1>
                <p class="text-gray-500 text-xs">
                  Numero di persone
                </p>
              </div>
              <div class="col-span-5">
                <on-numeric-stepper
                  v-model="formData.adults"
                  label="Adulti"
                  :value="formData.adults || 1"
                />
                <on-numeric-stepper
                  class="mt-4"
                  v-model="formData.children"
                  label="Bambini"
                  :value="formData.children"
                />
              </div>
            </div>
          </template>
          <template v-slot:action>
            <div class="col-span-5 col-start-3 gap-4">
              <v-btn
                color="primary"
                outlined
                @click="submitForm"
                :disabled="$v.$invalid"
                :loading="$store.state.bookings.loading"
                >{{ buttonText }}</v-btn
              >
              <span v-if="crudError" class="text-red-500 text-sm">{{
                crudError
              }}</span>
            </div>
          </template>
        </on-form>
      </div>
    </LeftMenuPage>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import OnForm from '@/_components/_ui/OnForm.vue'
import OnNumericStepper from '@/_components/_ui/OnNumericStepper.vue'
import { validationMixin } from 'vuelidate'
import {
  required,
  email,
  numeric,
  minLength,
  minValue
} from 'vuelidate/lib/validators'
import OnInput from '@/_components/_ui/OnInput.vue'

export default {
  components: {
    OnForm,
    LeftMenuPage: () => import('@/_templates/LeftMenuPage.vue'),
    ToolBar: () => import('@/_components/ToolBar.vue'),
    OnNumericStepper,
    OnInput
  },
  mixins: [validationMixin],
  data: () => ({
    isEdit: false,
    pageTitle: '',
    formData: {
      id: null,
      startDate: null,
      endDate: null,
      structureID: null,
      fullName: null,
      email: null,
      adults: null,
      children: null,
      status: null
    },
    buttonText: '',
    oldValues: null,
    crudError: null
  }),
  watch: {
    book: {
      deep: true,
      handler() {}
    },
    formData: {
      deep: true,
      handler() {}
    }
  },
  async created() {
    await this.$store.dispatch('structures/list', {
      status: 'published'
    })
  },
  computed: {
    ...mapGetters({ structures: ['structures/getData'] }),
    bookingTitle() {
      return this.$route.params.id
        ? 'Modifica la prenotazione'
        : 'Crea la prenotazione'
    },
    bookingSubtitle() {
      return this.$route.params.id
        ? ''
        : 'Segui le istruzioni per creare la prenotazione'
    }
  },
  validations: {
    formData: {
      startDate: {
        required
      },
      endDate: {
        required
      },
      structureID: { required },
      fullName: { required, minLength: minLength(2) },
      email: { required, email },
      adults: { minValue: minValue(1), numeric }
    }
  },
  methods: {
    submitForm() {
      try {
        this.crudError = null
        if (this.formData.id !== null) {
          this.updateBooking()
        } else {
          this.createBooking()
        }
      } catch (e) {
        console.log('Errore salvataggio form: ', e)
      }
    },
    async createBooking() {
      //controllo la disponibilità della struttura
      let data = this.formData
      let bookingsConflicts = []
      bookingsConflicts = await this.$store.dispatch(
        'bookings/isAvailable',
        data
      )
      console.log('Get Busy', bookingsConflicts.data)
      if (bookingsConflicts.data.listBookings.items.length === 0) {
        try {
          let book = await this.$store.dispatch('bookings/create', data)
          console.log('Risultato Creazione', book)
          console.log('Booking creato', book)
          this.$router.push({ name: 'bookingsList' })
        } catch (e) {
          this.crudError = 'Errore creazione: ' + e[0].message
        }
      } else {
        console.log(
          '%cErrore creazione prenotazione',
          'font-size:1.34rem;color:lime'
        )

        this.crudError = `Impossibile prenotare la strutture per il periodo ${data.startDate} - ${data.endDate}. La struttura risulta non dispnibile`
      }
    },
    async updateBooking() {
      try {
        let data = this.formData

        let busy = []
        if (
          this.oldValues.startDate == data.startDate &&
          this.oldValues.endDate == data.endDate &&
          this.oldValues.structureID == data.structureID
        ) {
          console.log(
            'Le date sono uguali. puoi fare la modifica',
            this.oldValues.startDate,
            data.startDate
          )
        } else {
          console.log('Le date sono diverse. verifico la disponibilità')
          busy = await this.$store.dispatch('bookings/isAvailable', data)
        }

        console.log('Get Busy', busy)

        if (busy.length == 0 || busy.data.listBookings.items.length === 0) {
          console.log(
            '%cPosso modificare il booking',
            'font-size:1.34rem;color:lime'
          )
          await this.$store.dispatch('bookings/update', {
            updated: this.updateData(data)
          })

          this.$router.push({ name: 'bookingsList' })
        } else {
          console.log(
            '%cNON posso modificare il booking',
            'font-size:1.34rem;color:tomato'
          )
          this.crudError = `Impossibile prenotare la strutture per il periodo ${data.startDate} - ${data.endDate}. La struttura risulta non dispnibile`
        }
      } catch (error) {
        console.log('Errore booking ', error)
        this.crudError = 'Errore booking ' + error
      }
    },
    async setStatus(e, b) {
      console.log('Set Status ', e, b)
      e.status = b
      if (this.formData.id) {
        try {
          let st = await this.$store.dispatch('bookings/update', {
            updated: {
              id: e.id,
              status: b
            }
          })

          console.log('Status', st)
        } catch (error) {
          console.log('Errore status ', error)
        }
      }
    },

    updateData(e) {
      return {
        id: e.id,
        structureID: e.structureID,
        startDate: e.startDate,
        endDate: e.endDate,
        adults: e.adults,
        children: e.children,
        fullName: e.fullName,
        email: e.email
      }
    }
  },
  async mounted() {
    try {
      if (this.$route.params.id) {
        console.log('Carico il booking ', this.$route.params.id)
        let bk = await this.$store.dispatch(
          'bookings/getBooking',
          this.$route.params.id
        )
        console.log('Book ', bk)
        this.buttonText = 'Salva modifiche'

        this.formData = bk
        this.oldValues = bk
        this.oldValues = Object.freeze({ ...this.oldValues })
        this.isEdit = true
        this.pageTitle = 'Modifica'
      } else {
        this.buttonText = 'Crea'
        this.pageTitle = 'Crea'
      }
    } catch (error) {
      console.log('Errore caricamento booking', error)
    }
  }
}
</script>

<style></style>
