<template>
  <div class="flex flex-row justify-between items-center">
    <span>{{ label }}</span>
    <div>
      <div class="flex items-center justify-center gap-4">
        <button
          :disabled="value == 0 ? true : false"
          class="h-8 w-8 rounded-full border-gray-300 border-2 border-solid
        hover:border-gray-500 text-gray-500 cursor-pointer"
          @click.prevent="decrementValue"
        >
          -
        </button>
        <span>{{ value }}</span>
        <button
          class="h-8 w-8 rounded-full border-gray-300 border-2 border-solid
        hover:border-gray-500 text-gray-500 cursor-pointer"
          @click.prevent="incrementValue"
        >
          +
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OnNumericStepper',
  model: {
    prop: 'newVal',
    event: 'change'
  },
  props: {
    value: {
      type: Number,
      default: null
    },
    stepperIncrDecValue: {
      type: Number,
      default: 1
    },
    label: String
  },
  data: () => ({
    newVal: 0
  }),
  watch: {
    newVal: {
      immediate: true,
      handler(n) {
        this.$emit('change', n)
      }
    }
  },
  methods: {
    incrementValue() {
      this.newVal = parseInt(this.newVal) + this.stepperIncrDecValue

      // this.localValue += this.stepperIncrDecValue
      // this.$emit('change', this.value)
    },
    decrementValue() {
      this.newVal = parseInt(this.newVal) - this.stepperIncrDecValue

      // this.localValue -= this.stepperIncrDecValue
      // this.$emit('change', this.value)
    }
  },
  mounted() {
    // eslint-disable-next-line no-extra-boolean-cast
    this.newVal = !!this.value ? this.value : 0
  }
}
</script>

<style lang="postcss"></style>
